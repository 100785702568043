$(document).ready(function () {
    var $conferences = $('.js-landing-conferences');
    var $news = $('.js-landing-news');

    if($news.length > 0 && $conferences.length <= 0){
        $news.removeClass('col-sm-8').addClass('col-sm-12');
    }

    var $promo_animation = $('#promo-animation');
    setTimeout(function () {
        $promo_animation.fadeIn(500, function() {
            $promo_animation.animate({
                top: "80px",
                right: "50%"
            }, 1000, function() {
                setInterval(function(){blink($promo_animation.find('i'))}, 1000);
            });
        });
    }, 2000);

    document.addEventListener('DOMContentLoaded', function () {
        const toggleButton = document.querySelector('.icon-menu-toggle');
        const menuWrap = document.querySelector('.icon-menu-wrap');

        toggleButton.addEventListener('click', function () {
            menuWrap.classList.toggle('hidden');
        });
    });
});

function blink(el) {
    el.fadeTo(100, 0.1).fadeTo(200, 1.0);
}